<template>
	<v-footer app padless absolute color="#ffffff">
		<v-card flat width="100%">
			<v-container>
				<v-row>
					<v-col cols="6" xl="4" class="ml-auto d-flex justify-center">
						<strong class="subheading">Contact Me</strong>
					</v-col>
					<v-col cols="6" class="d-flex justify-center">
						<v-btn v-for="icon in icons" :key="icon.content" :href="icon.link" icon>
							<v-icon size="24px">{{ icon.content }}</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
			<v-divider></v-divider>

			<v-card-text class="mt-2 py-0 text-center">
				Copyright © {{ new Date().getFullYear() }}
				<v-btn text href="https://sumnersautoservice.ca">Sumner's Auto Service</v-btn>
			</v-card-text>
			<v-card-text class="py-1 text-center">
				Built by
				<v-btn text href="https://okrella.com/">Okrella</v-btn>with ❤️
			</v-card-text>
		</v-card>
	</v-footer>
</template>


<script>
export default {
	data: () => {
		return {
			icons: [
				{
					content: "mdi-phone",
					link: "tel:(905) 616-6116",
				},
				{
					content: "mdi-email",
					link: "mailto:sumnersautoservice@gmail.com",
				},
				{
					content: "mdi-map-marker-radius-outline",
					link:
						"https://www.google.com/maps?ll=43.185574,-79.930069&z=13&t=m&hl=en-US&gl=US&mapclient=embed&cid=2530330770434810305",
				},
			],
		};
	},
};
</script>

<style lang="scss">
</style>