import Vue from 'vue'
import Vuex from 'vuex'
import { testimonialCollection, publicestimonialCollection } from "./firebase";

Vue.use(Vuex)

const formatIncomingTestimonialData = function (querySnapshot) {
	const testimonialsTemp = [];
	querySnapshot.forEach((doc) => {
		const data = doc.data();
		testimonialsTemp.push({
			id: doc.id,
			name: data.name,
			comment: data.comment,
			dateAdded: new Date(data.dateAdded),
		});
	});
	return testimonialsTemp;
};


export default new Vuex.Store({
	state: {
		allTestimonials: []
	},
	mutations: {
		addTestimonial(state, newTestimonial) {
			state.allTestimonials.push(newTestimonial);
		},
		UpdateTestimonials(state, newTestimonials) {
			state.allTestimonials = newTestimonials
		}
	},
	actions: {
		fetchTestimonials({ commit }) {
			return new Promise((resolve, reject) => {
				publicestimonialCollection.get()
					.then((querySnapShot) => {
						commit(
							"UpdateTestimonials", formatIncomingTestimonialData(querySnapShot)
						);
						resolve();
					}).catch((error) => {
						reject(error);
					});
			});
		},
		addTestimonial({ commit }, newTestimonial) {
			return new Promise((resolve, reject) => {
				newTestimonial["dateAdded"] = new Date().toJSON();
				testimonialCollection.add(newTestimonial).then((docRef) => {
					newTestimonial["id"] = docRef.id;
					commit("addTestimonial", newTestimonial);
					// Add the same testimonial to the public collection
					publicestimonialCollection.add({
						name: newTestimonial.name,
						comment: newTestimonial.comment,
						dateAdded: newTestimonial.dateAdded,
					});
					resolve();
				}).catch((error) => {
					reject(error);
				})
			})
		}
	},
	modules: {
	},
	getters: {
		allTestimonials(state) {
			return state.allTestimonials;
		}
	}
})
