import firebase from "firebase/app";
import "firebase/firestore";
import config from "./config";

// Initialize firebase config
firebase.initializeApp(config);

// Instantiate the db connection
const db = firebase.firestore();

// Initialize the testimonial collection
const testimonialCollection = db.collection("testimonials");

// Initialize the public testimonials collection
const publicestimonialCollection = db.collection("publicTestimonials");

// Initialize the contact request db collection
const contactRequestCollection = db.collection("contactRequests");

export {
	testimonialCollection,
	contactRequestCollection,
	publicestimonialCollection
}