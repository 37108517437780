<template>
	<v-container>
		<v-row>
			<v-col cols="12" sm="1" md="2" lg="3" xl="4"></v-col>
			<v-col cols="12" sm="10" md="8" lg="6" xl="4" class="content">
				<div class="heading text-h4">Benefits of working with me</div>
				<span
					class="subtitle"
				>I've been in the business for 13 years. I've handled a lot of car repairs and have acquired the best quality products, adopted highest professional standards and ensured the best quality service for my customers.</span>
			</v-col>
			<v-col cols="12" sm="1" md="2" lg="3" xl="4"></v-col>
			<v-col cols="12" md="6" class="d-none d-lg-flex align-center illustration">
				<IntroIllustration class="intro-illustraion" />
			</v-col>
			<v-col cols="12" lg="6" class="selling-point ml-auto mr-auto">
				<v-container>
					<v-row>
						<v-col
							cols="12"
							sm="6"
							md="4"
							lg="6"
							class="ml-auto mr-auto"
							v-for="sp in sellingPoints"
							:key="sp.icon"
						>
							<v-card height="250" width="259" elevation="24" class="ml-auto mr-auto mr-lg-none">
								<v-card-title class="subtitle-1">{{sp.label}}</v-card-title>
								<v-card-text>{{sp.description}}</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import IntroIllustration from "../../assets/Illustrations/Intro illustration.svg";
export default {
	data: () => {
		return {
			sellingPoints: [
				{
					label: "Experienced Maintenance",
					description:
						"With many proud members of the 300k+ club, several 400k+ club and a 500k+ club elite member and going strong! I understand what it takes to keep your car on the road as long as possible!",
				},
				{
					label: "Every job is personal",
					description:
						"If you want the quality you would expect from the dealership, but with a more personal and friendly atmosphere, you have found it",
				},
				{
					label: "Top quality products",
					description:
						"I have invested in all the latest specialist tools and diagnostic software that is specifically tailored for your vehicle!",
				},
				{
					label: "Professional Standards",
					description:
						"I do exactly what needs to be done. I do not overcharge and I do not hide what I do.",
				},
			],
		};
	},
	components: {
		IntroIllustration,
	},
};
</script>

<style lang="scss">
.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	.heading {
		color: #8c9eff;
	}
}
.selling-point {
	text-align: center;
	.v-card__title {
		display: inline-block;
		text-align: center;
	}
	.v-card__text {
		svg {
			max-height: 64px;
			max-width: 64px;
		}
	}
}
.illustration {
	svg {
		width: 100%;
		min-height: 250px;
	}
}
</style>