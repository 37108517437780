import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import goTo from 'vuetify/es5/services/goto';

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: "/contact",
		name: 'Contact',
		component: () => import(
			/* webpackChunkName: "Contact" */
			"../views/Contact.vue"
		)
	},
	{
		path: "/testimonials",
		name: 'Testimonials',
		component: () => import(
			/* webpackChunkName: "Testimonial" */
			"../views/Testimonials.vue"
		),
	},
	{
		path: "/services",
		name: 'Services',
		component: () => import(
			/* webpackChunkName: "Services" */
			"../views/Services.vue"
		),
	},
	{
		path: "/404",
		name: "NotFound",
		component: () =>
			import(
				/* webpackChunkName: "NotFound" */
				"../views/NotFound404.vue"
			)
	},
	{
		path: "*",
		redirect: { name: "NotFound" }
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior: (to, from, savedPosition) => {
		let scrollTo = 0;
		if (to.hash) {
			scrollTo = to.hash
		}
		else if (savedPosition) {
			scrollTo = savedPosition.y;
		}
		return goTo(scrollTo, {
			offset: 64
		});
	}
})

export default router
