<template>
	<v-container class="landing-container">
		<v-row>
			<v-col cols="12" sm="6" xl="4" class="heading ml-auto">
				<div class="title text-h3 text-sm-h4 text-md-h3 text-lg-h2">Mechanic Services</div>
				<div class="title text-h3 text-sm-h4 text-md-h3 text-lg-h2">in Hamilton, ON</div>
				<div class="cta">
					<div class="subtitle">I can keep your car on the road longer</div>
					<ContactLinks />
				</div>
			</v-col>
			<v-col cols="12" sm="6" class="illustration-container">
				<AboutIllustration />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import AboutIllustration from "../../assets/Illustrations/About Illustration Mobile.svg";
import ContactLinks from "../ContactLinks";
export default {
	components: {
		AboutIllustration,
		ContactLinks,
	},
};
</script>

<style lang="scss">
.landing-container {
	.illustration-container {
		svg {
			max-height: 500px;
		}
	}
	.heading {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		.title {
			font-family: "Poppins", sans-serif !important;
			font-weight: 900 !important;
			color: #1c1c1c;
			width: 100%;
		}
		.cta {
			margin-top: 5px;
			width: 100%;
			color: rgba(28, 28, 28, 0.6);
			.v-btn {
				text-transform: none !important;
				margin-right: 20px;
				margin-top: 4px;
			}
		}
	}
}
</style>