<template>
	<div id="aboutMe">
		<v-container>
			<v-row>
				<v-col cols="12" sm="1" md="2" lg="3" xl="4"></v-col>
				<v-col cols="12" sm="10" md="8" lg="6" xl="4" class="content">
					<div class="heading text-h4">About me</div>
				</v-col>
				<v-col cols="12" sm="1" md="2" lg="3" xl="4"></v-col>
				<v-col cols="12" md="6" class="d-flex align-center flex-column">
					<v-card elevation="0">
						<v-card-title class="pb-0">Hello, </v-card-title>
						<v-card-title class="pt-1">
							Welcome to Sumner’s Auto Services' website.
						</v-card-title>
						<v-card-text>I’ve recently expanded my business in order to serve you better. Same top notch service, same great quality work, same great smile while I treat your car like my own! Please feel free to call and set up an appointment to fix or maintain your automobile. Nothing needed at this point? Come on by and check out the new facilities, and to see how I can serve you better. Thanks for your continuous support!</v-card-text>
					</v-card>
					<ContactLinks />
				</v-col>
				<v-col cols="12" md="6">
					<AboutIllustration class="intro-illustraion" />
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import AboutIllustration from "../../assets/Illustrations/About illustration.svg";
import ContactLinks from "../ContactLinks";
export default {
	components: {
		AboutIllustration,
		ContactLinks,
	},
};
</script>

<style lang="scss">
#aboutMe {
	svg {
		max-height: 500px;
	}
	.v-card {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		text-align: center;
		.v-card__title,
		.v-card__text,
		.v-card__actions {
			display: inline-block;
			word-break: break-word;
		}
	}
}
</style>