<template>
	<v-app>
		<Navbar v-model="isOpen" :routes="routes" />
		<NavDrawer v-model="isOpen" :routes="routes" />
		<router-view class="main-content"></router-view>
		<AppFooter />
	</v-app>
</template>


<script>
import Navbar from "./components/Navigation/Navbar";
import NavDrawer from "./components/Navigation/NavDrawer";
import AppFooter from "./components/AppFooter";
export default {
	data: () => {
		return {
			isOpen: false,
			routes: [
				{
					text: "Home",
					path: "/",
				},
				{
					text: "About",
					path: "/#aboutMe",
				},
				{
					text: "Policies",
					path: "/#policies",
				},
				{
					text: "Contact",
					path: "/contact",
				},
				{
					text: "Testimonials",
					path: "/testimonials",
				},
				{
					text: "Services",
					path: "/services",
				},
			],
		};
	},
	components: {
		AppFooter,
		Navbar,
		NavDrawer,
	},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,500;1,900&display=swap");
* {
	font-family: "Poppins", sans-serif !important;
}
.main-content {
	padding-bottom: 200px;
	padding-top: 64px;
}
</style>