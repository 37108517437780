<template>
	<v-app-bar
		:color="showToggle? 'transparent': '#ffffff'"
		height="56"
		app
		fixed
		:elevate-on-scroll="!showToggle"
		:flat="showToggle"
		scroll-threshold="64"
	>
		<v-btn
			class="v-app-bar__nav-icon"
			color="#ffffff"
			v-if="showToggle"
			@click="updateValue"
			icon
			elevation="12"
			height="112"
			width="112"
		>
			<v-icon color="#8c9eff">mdi-menu</v-icon>
		</v-btn>
		<v-container v-else>
			<v-row class="links-container">
				<v-col cols="6" md="4" xl="4" class="ml-auto">
					<v-toolbar-title>Sumners Auto Service</v-toolbar-title>
				</v-col>
				<v-col cols="6" md="8" class="text-center">
					<v-btn
						exact-active-class="link-active"
						class="nav-link mr-3"
						text
						v-for="route in routes"
						:key="route.path"
						:to="route.path"
					>{{ route.text }}</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</v-app-bar>
</template>

<script>
export default {
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		routes: {
			type: Array,
			required: true,
		},
	},
	methods: {
		updateValue() {
			this.$emit("input", true);
		},
	},
	computed: {
		showToggle() {
			const breakpointName = this.$vuetify.breakpoint.name;
			return breakpointName === "xs" || breakpointName == "sm";
		},
	},
};
</script>

<style lang="scss">
// Styles for the navbar toggle
.v-app-bar__nav-icon {
	background-color: #ffffff !important;
	margin-left: -16px !important;
	align-items: flex-end !important;
	transform: translate(-50%, -56%);
	position: fixed !important;
	top: 0 !important;
	.v-btn__content {
		margin-bottom: 16px;
		margin-left: 44px;
	}
}
.nav-link {
	text-transform: none !important;
	&.link-active {
		color: #8c9eff !important;
	}
}
.links-container {
	align-items: center;
}
</style>