<template>
	<div class="home">
		<LandingSection />
		<Introduction />
		<AboutMe />
		<Policies />
	</div>
</template>

<script>
import LandingSection from "../components/Sections/LandingSection";
import Introduction from "../components/Sections/Introduction";
import AboutMe from "../components/Sections/AboutMe";
import Policies from "../components/Sections/Policies";

export default {
	components: {
		LandingSection,
		Introduction,
		AboutMe,
		Policies,
	},
	watch: {
		$route(to, from) {
			console.log(to);
		},
	},
};
</script>
