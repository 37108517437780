<template>
	<div id="policies">
		<v-container>
			<v-row>
				<v-col cols="12" sm="1" md="2" lg="3" xl="4"></v-col>
				<v-col cols="12" sm="10" md="8" lg="6" xl="4" class="content">
					<div class="heading text-h4">Policies</div>
				</v-col>
				<v-col cols="12" sm="1" md="2" lg="3" xl="4"></v-col>
				<v-col cols="12" md="10" lg="8" class="ml-auto mr-auto">
					<v-row>
						<v-col
							cols="12"
							md="6"
							class="d-flex align-center flex-column ml-auto mr-auto"
							v-for="policy in policies"
							:key="policy.title"
						>
							<v-card :elevation="elevation" height="100%">
								<v-card-title>{{policy.title}}</v-card-title>
								<v-card-subtitle class="align-left" v-if="policy.title === 'Payment'">
									<v-img position="center top" height="64" width="64" contain src="../../assets/cash.png"></v-img>
									<v-img position="center top" height="64" width="64" contain src="../../assets/cheque.png"></v-img>
									<v-img position="center top" height="64" width="64" contain src="../../assets/interac.jpg"></v-img>
								</v-card-subtitle>
								<v-card-text v-html="policy.description"></v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
export default {
	data: () => {
		return {
			policies: [
				{
					title: "Appointments",
					description:
						"When you book an appointment, that time is set aside for you – and only you. I never double book and strive to always be on time ready for you when you arrive. In return, I ask that you are prompt and attend to your appointment on time.<br/>Please understand that automotive technicians only get paid when we deliver a service… therefore missed appointments are costly and prevent me providing services for other clients.",
				},
				{
					title: "Cancellations",
					description:
						"In the event that you are not able to keep your scheduled appointment, please contact me at least 24 hour prior to your appointment. If I do not hear from you within this time frame and you miss your appointment, you may be required to pay the full cost of the appointment booked.<br/>Note: I acknowledge that no one is perfect and there are circumstances that are out of our control (sudden illness, extreme weather, etc.) and so I may make exceptions to the above policy where applicable.",
				},
				{
					title: "Payment",
					description:
						"I accept all methods of payments. Including cash, cheques, E transfers, PayPal, debit and most credit cards (3% fee applies).",
				},
			],
		};
	},
	computed: {
		elevation() {
			switch (this.$vuetify.breakpoint.name) {
				case "xl":
				case "lg":
				case "md":
					return 24;
				case "sm":
				case "xs":
				default:
					return 0;
			}
		},
	},
};
</script>

<style lang="scss">
#policies {
	.v-card {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		align-items: center;
		text-align: center;
		.v-card__title,
		.v-card__text,
		.v-card__actions {
			display: inline-block;
		}
		.v-card__subtitle {
			display: flex;
			width: 100%;
			justify-content: space-evenly;
		}
		.v-card__text {
			text-align: start !important;
		}
	}
}
</style>